// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import { useAuth } from "../modules/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbS6NxHMSWsRmTGoQL1KUJRaFoX5pPsas",
  authDomain: "amutelecom-front.firebaseapp.com",
  projectId: "amutelecom-front",
  storageBucket: "amutelecom-front.appspot.com",
  messagingSenderId: "127638929210",
  appId: "1:127638929210:web:5580f91082ca50a28c9a03",
  measurementId: "G-GYJDH0EF9J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);
const API_URL = process.env.REACT_APP_THEME_API_URL;
export const requestForTokenAndPermission = (id) => {
    const FCM_URL = `${API_URL}/admin/set-fcm-token/${id}`;
    return new Promise((resolve) => {
      // Check if the browser supports notifications
      if ("Notification" in window) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            // Permission granted, request FCM token
            getToken(messaging, { vapidKey:"BBETyMb7tr1J1N9ehKiXqibjrskSgicRlrALbz68qbFktJiWrqZQ3ZBzhsnvg4mmOKjxZo5bZI5v1MymmEc5lM4" })
              .then((currentToken) => {
                if (currentToken) {
                    console.log(currentToken,'its token')
                  // Display a confirmation dialog to the user
                  const confirmNotification =true
  
                  if (confirmNotification) {
                    // Send a request to the server to set the FCM token using Axios
                    axios
                      .get(FCM_URL,{
                        params: {
                          fcm_token: currentToken, // Include the FCM token as a query parameter
                        },
                      }
                      )
                      .then((response) => {
                        if (response.status === 200) {
                          console.log("Token set on the server successfully.");
                        } else {
                          console.log("Failed to set the token on the server.");
                        }
                      })
                      .catch((err) => {
                        console.error("Error while sending the request:", err);
                      });
                  }
                } else {
                  console.log("No registration token available.");
                }
              })
              .catch((err) => {
                console.error("An error occurred while retrieving token:", err);
              });
          } else {
            console.log("Notification permission denied by the user.");
          }
          resolve(permission);
        });
      } else {
        console.log("Browser does not support notifications.");
        resolve("unsupported");
      }
    });
  };
  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });