// @ts-nocheck
import {Column} from 'react-table'
import {OrderInfoCell} from './OrderInfoCell'
import {OrderLastLoginCell} from './OrderLastLoginCell'
import {OrderTwoStepsCell} from './OrderTwoStepsCell'
import {OrderActionsCell} from './OrderActionsCell'
import {OrderSelectionCell} from './OrderSelectionCell'
import {OrderCustomHeader} from './OrderCustomHeader'
import {OrderSelectionHeader} from './OrderSelectionHeader'
import {Order} from '../../core/_models'
import { FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'

const ordersColumns: ReadonlyArray<Column<Order>> = [
  {
    Header: (props) => <OrderSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <OrderSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.RESELLERNAME' />}
        className='min-w-125px'
      />
    ),
    accessor: 'reseller.reseller_name',
    Cell: ({...props}) => <OrderInfoCell order={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.RECHARGEABLEACCOUNT' />}
        className='min-w-125px'
      />
    ),
    accessor: 'rechargeble_account',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.BUNDLEID' />}
        className='min-w-125px'
      />
    ),
    accessor: 'bundle.id',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.PAYABLEAMOUNT' />}
        className='min-w-125px'
      />
    ),
    accessor: 'bundle.buying_price',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.BUNDLETITLE' />}
        className='min-w-125px'
      />
    ),
    accessor: 'bundle.bundle_title',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.BUNDLEDESCRIPTION' />}
        className='min-w-125px'
      />
    ),
    accessor: 'bundle.bundle_description',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.REJECTREASON' />}
        className='min-w-125px'
      />
    ),
    accessor: 'reject_reason',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.COMPANYNAME' />}
        className='min-w-125px'
      />
    ),
    accessor: 'bundle.service.company.company_name',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.CATEGORYNAME' />}
        className='min-w-125px'
      />
    ),
    accessor: 'bundle.service.service_category.category_name',
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.ORDEREDDATE' />}
        className='min-w-125px'
      />
    ),
    accessor: 'created_at',
    Cell: ({ value }) => (
      <span>
        {dayjs(value).format('MMMM DD, YYYY HH:mm:ss')}
      </span>
    ),
  },
  {
    Header: (props) => (
      <OrderCustomHeader
        tableProps={props}
        title={<FormattedMessage id='ORDER.TABLE.COLUMN.STATUS' />}
        className='min-w-125px'
      />
    ),
    accessor: 'status',
    Cell: ({ value }) => {
      const renderStatusBadge = () => {
        if (value == 1) {
          return <span className="badge badge-success">Confirmed Order</span>;
        } else if (value == 0) {
          return <span className="badge badge-danger">Pending Order</span>;
        } else if (value == 2) {
          return <span className="badge badge-danger">Rejected Order</span>;
        }
        return null; // Handle other cases or return nothing
      };

      return renderStatusBadge();
    },
  },
  {
    Header: (props) => (
      <OrderCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <OrderActionsCell id={props.data[props.row.index].id} order={props.data[props.row.index] } 
    />,
  },
  
  
];


export {ordersColumns}
