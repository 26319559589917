import axios, {AxiosResponse} from 'axios'
import {ID, AmutelecomResponse, Response} from '../../../../../_metronic/helpers'
import {Bundle, BundlesQueryResponse} from './_models'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BUNDLE_URL = `${API_URL}/admin/bundles`
const GET_BUNDLES_URL = `${API_URL}/admin/bundles`

const getBundles = (query: string): Promise<BundlesQueryResponse | AmutelecomResponse<Bundle[]>> => {
  return axios
    .get(`${GET_BUNDLES_URL}?${query}`)
    .then((d: AxiosResponse<AmutelecomResponse<Bundle[]>>) => {
      return d.data
    })
}

const getBundleById = async (id: ID): Promise<Bundle> => {
  try {
    const {data} = await axios.get(`${BUNDLE_URL}/${id}`)
    return data?.data?.bundle
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${BUNDLE_URL}/${id}`).then((response: AxiosResponse<Bundle>) => response.data)
}
const createBundle = (bundle: Bundle): Promise<Bundle | undefined> => {
  return axios
    .post(BUNDLE_URL, bundle)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Bundle>) => response.data)
}


const updateBundle = (bundle: Bundle): Promise<Bundle | undefined> => {
  return axios
    .put(`${BUNDLE_URL}/${bundle.id}`, bundle)
    .then((response: any) =>
    {if(response.data.success == true){
      toast.success(response.data.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  return response.data})
    .then((response: Response<Bundle>) => response.data)
}

const deleteBundle = (bundleId: ID): Promise<void> => {
  return axios.delete(`${BUNDLE_URL}/${bundleId}`).then((response) => {
    if(response.data.success == true){
      toast.success(response?.data?.message,{position:'top-right'})
    }else{
      toast.error(response?.data?.message,{position:'top-right'})
    }
  })
}

const deleteSelectedBundles = (bundleIds: Array<ID>): Promise<void> => {
  const requests = bundleIds.map((id) => axios.delete(`${BUNDLE_URL}/${id}`))
  return axios.all(requests).then((response) => {})
}

export {
  getBundles,
  deleteBundle,
  deleteSelectedBundles,
  getBundleById,
  createBundle,
  updateBundle,
}
