import {ID, AmutelecomResponse, Response} from '../../../../../_metronic/helpers'
// import { District, Province } from '../../../district/districts-list/core/_models'
export type Currency = {
  id?: any
  name?: string
  code?: string
  country_id?: any
  exchange_rate?: string
  create_at?: Date
  updated_at?: Date
}
export type Country = {
  id?: any
  country_name?: string
  language_id?: any
  province?: Currency
  updated_at?: Date
  created_at?: Date
}

export type Province = {
  id?: any
  province_name?: string
  country_id?: string
}
export type District = {
  id?: any
  district_name?: string
  province?: Province
}
export type DistrictsQueryResponse = AmutelecomResponse<District[]>

export const initialDistrict: District = {
  id: '',
  district_name: '',
  province: {
    id: '',
    province_name: '',
    country_id:''
  },
}
