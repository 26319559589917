// @ts-nocheck
import {Column} from 'react-table'
import {ServiceCategoryInfoCell} from './ServiceCategoryInfoCell'
import {ServiceCategoryLastLoginCell} from './ServiceCategoryLastLoginCell'
import {ServiceCategoryTwoStepsCell} from './ServiceCategoryTwoStepsCell'
import {ServiceCategoryActionsCell} from './ServiceCategoryActionsCell'
import {ServiceCategorySelectionCell} from './ServiceCategorySelectionCell'
import {ServiceCategoryCustomHeader} from './ServiceCategoryCustomHeader'
import {ServiceCategorySelectionHeader} from './ServiceCategorySelectionHeader'
import {ServiceCategory} from '../../core/_models'
import { FormattedMessage } from 'react-intl'

const service_categoriesColumns: ReadonlyArray<Column<ServiceCategory>> = [
  {
    Header: (props) => <ServiceCategorySelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <ServiceCategorySelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <ServiceCategoryCustomHeader tableProps={props} title={<FormattedMessage id='SERVICECATEGORY.TABLE.COLUMN.SERVICECATEGORYNAME' />} className='min-w-125px' />,
    id: 'category_name',
    accessor:'category_name'
  },
  {
    Header: (props) => <ServiceCategoryCustomHeader tableProps={props} title={<FormattedMessage id='SERVICECATEGORY.TABLE.COLUMN.SERVICECATEGORYTYPE' />} className='min-w-125px' />,
    id: 'type',
    accessor:'type'
  },
  // {
  //   Header: (props) => <ServiceCategoryCustomHeader tableProps={props} title={<FormattedMessage id='SERVICECATEGORY.TABLE.COLUMN.LANGUAGE' />} className='min-w-125px' />,
  //   accessor: 'language',
  // },
  {
    Header: (props) => (
      <ServiceCategoryCustomHeader tableProps={props} title={<FormattedMessage id='TABLE.GENERAL.ACTION' />} className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ServiceCategoryActionsCell id={props.data[props.row.index].id} />,
  },
]

export {service_categoriesColumns}
