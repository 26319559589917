/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/admin/pages/resellers'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.RESELLER' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/service_category'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.SERVICECATEGORY' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/services'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.SERVICE' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/bundles'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.BUNDLE' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/orders'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.ORDER' })}
        fontIcon='bi-layers'
      />
        <AsideMenuItem
        to='/admin/pages/transactions'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.MONEYTRANSACTIONS' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/companies'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.COMPANY', defaultMessage: "Company" })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/company_codes'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.COMPANYCODE', defaultMessage: "Company Code" })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/countries'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.COUNTRY' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/provinces'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.PROVINCE' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/districts'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.DISTRICT' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/languages'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.LANGUAGE' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/currencies'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.CURRENCY' })}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/admin/pages/advertisements'
        icon='/media/icons/duotune/general/gen051.svg'
        title={intl.formatMessage({ id: 'MENU.ADVERTISEMENT' })}
        fontIcon='bi-layers'
      />

      {/* <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
          </div>
        </div> */}

      {/* <div className='menu-item'>
          <div className='menu-content'>
            <div className='separator mx-1 my-4'></div>
          </div>
        </div>
        <div className='menu-item'>
          <a
            target='_blank'
            className='menu-link'
            href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          >
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
          </a>
        </div> */}
    </>
  )
}
