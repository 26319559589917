import axios, {AxiosResponse} from 'axios'
import {ID, AmutelecomResponse, Response} from '../../../../../_metronic/helpers'
import {Transaction, TransactionsQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const TRANSACTION_URL = `${API_URL}/admin/transactions`
const GET_TRANSACTIONS_URL = `${API_URL}/admin/transactions`

const getTransactions = (query: string): Promise<TransactionsQueryResponse | AmutelecomResponse<Transaction[]>> => {
  return axios
    .get(`${GET_TRANSACTIONS_URL}?${query}`)
    .then((d: AxiosResponse<AmutelecomResponse<Transaction[]>>) => {
      return d.data
    })
}

const getTransactionById = async (id: ID): Promise<Transaction> => {
  try {
    const {data} = await axios.get(`${TRANSACTION_URL}/${id}`)
    return data?.data?.transaction
  } catch (error: any) {
    console.log('Error: ', error.message)
    return {}
  }
  // return axios.get(`${TRANSACTION_URL}/${id}`).then((response: AxiosResponse<Transaction>) => response.data)
}
const createTransaction = (transaction: Transaction): Promise<Transaction | undefined> => {
  return axios
    .post(TRANSACTION_URL, transaction)
    .then((response: AxiosResponse<Response<Transaction>>) => response.data)
    .then((response: Response<Transaction>) => response.data)
}

const updateTransaction = (transaction: Transaction): Promise<Transaction | undefined> => {
  return axios
    .put(`${TRANSACTION_URL}/${transaction.id}`, transaction)
    .then((response: AxiosResponse<Response<Transaction>>) => response.data)
    .then((response: Response<Transaction>) => response.data)
}

const deleteTransaction = (transactionId: ID): Promise<void> => {
  return axios.delete(`${TRANSACTION_URL}/${transactionId}`).then(() => {})
}
const confirmTransaction = (transactionId: ID): Promise<void> => {
  return axios.get(`${TRANSACTION_URL}/confirm-transaction/${transactionId}`).then(() => {})
}
const deleteSelectedTransactions = (transactionIds: Array<ID>): Promise<void> => {
  const requests = transactionIds.map((id) => axios.delete(`${TRANSACTION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getTransactions,
  deleteTransaction,
  confirmTransaction,
  deleteSelectedTransactions,
  getTransactionById,
  createTransaction,
  updateTransaction,
}
